import axios from 'axios';

import { IMasterTable, IMasterTableData, ISearchParam } from '../../types/models/master-table';
import { API_URL } from '../../constants';
import { LookupType } from '../../types/models/lookup';
import { createError } from '../error';
import responseParser from '../response-parser';

export const PERSONAS_CATALOG_API_URL = `${API_URL}/catalog-api?name=personas`;

export async function getMasterTables(): Promise<IMasterTable[]> {
  const url = `${API_URL}/catalog-api`;
  try {
    const response = await axios.get(url);
    return responseParser(response) as IMasterTable[];
  } catch (err: any) {
    throw createError(err);
  }
}
getMasterTables.QUERY_KEY = 'getMasterTables';

export async function getLookupListByType(name: LookupType): Promise<any> {
  const majorUrl = name === LookupType.MAJOR_CLASSIFICATION_GROUPS ? '/primary-majors' : '';
  const url = `${API_URL}/catalog-api${majorUrl}`;
  try {
    const response = await axios.get(url, { params: { name } });
    return responseParser(response);
  } catch (err: any) {
    throw createError(err);
  }
}

export async function getSchoolList(search: string): Promise<any> {
  let url = `${API_URL}/catalog-api?name=university`;
  if (search) {
    url += `&search=${search}`;
  }
  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (err: any) {
    throw createError(err);
  }
}
getSchoolList.QUERY_KEY = 'getSchoolList';
getSchoolList.QUERY_KEY_FILTERED = 'getSchoolListFiltered';

export async function getMajorsList(): Promise<any> {
  const url = `${API_URL}/catalog-api?name=major-classification`;
  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (err: any) {
    throw createError(err);
  }
}
getMajorsList.QUERY_KEY = 'getMajorsList';

export async function getGenderList(): Promise<any> {
  const url = `${API_URL}/catalog-api?name=sex`;
  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (err: any) {
    throw createError(err);
  }
}
getGenderList.QUERY_KEY = 'getGenderList';

export async function getByUrl(url: string, params: ISearchParam = {}): Promise<IMasterTableData[]> {
  try {
    let apiUrl = url;
    if (!apiUrl?.startsWith('http')) {
      // Sometimes url is not built correctly, so we add the API_URL here
      apiUrl = `${API_URL}${url.startsWith('/') ? url : `/${url}`}`;
    }

    const response = await axios.get(apiUrl, { params });
    const data: IMasterTableData[] = responseParser(response);
    return data;
  } catch (err: any) {
    throw createError(err);
  }
}

export async function getUniversities(search: string): Promise<any[]> {
  try {
    const url = `${API_URL}/catalog`;
    const response = await axios.get(url, {
      params: {
        search,
        entity: 'university',
        columns: ['id', 'schoolName'],
      },
    });
    return responseParser(response);
  } catch (err: any) {
    throw createError(err);
  }
}
getUniversities.QUERY_KEY = 'public:getUniversities';
