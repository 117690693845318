import React from 'react';
import { PopoverProps } from 'antd5/lib/popover';

import { Popover as $Popover } from '../components/common/antd/antd-override';

export default function Popover({ children, overlayInnerStyle, ...props }: PopoverProps) {
  return (
    <$Popover {...props} overlayInnerStyle={overlayInnerStyle}>
      {children}
    </$Popover>
  );
}
