import { Col, Row } from 'antd';
import React from 'react';
import { Link, graphql, navigate } from 'gatsby';
import { useSelector } from 'react-redux';

import {
  AboutBody,
  AboutImage,
  AboutInfoContainer,
  BtnGroupWrapper,
  CalendarItem,
  CalendarWrapper,
  DayTile,
  DividerWrapper,
  HeaderWrapper,
  ItemBox,
  ScholarshipHeader,
  ScholarshipMainContainer,
  SectionBody,
  SectionTitle,
  SectionWrapper,
  SigninBtn,
  SponsorItem,
  SponsorLogo,
  SponsorLogosWrapper,
  SponsorType,
  SponsorsContainer,
  SponsorsSectionTitle,
  SponsorsWrapper,
  StatusClosed,
  StatusOpened,
  StatusTile,
  StatusValue,
  StatusWrapper,
  YearTile,
} from './scholarship-styles';

import {
  ScholarshipObject,
  ScholarshipSection,
  ScholarshipStage,
  SponsorObject,
} from '../../types/cms/models/scholarship';
import { Button } from '../../components';

import PageHeader from '../../components/common/page-header';
import PublicLayout from '../../layout/public-layout';
import { isAuthenticated } from '../../state/selectors/auth';
import { useI18NContext } from '../../i18n';

type ScholarshipMainProps = {
  data: {
    navigationItem: any;
    scholarship: ScholarshipObject;
    scholarshipStages: ScholarshipStage;
    scholarshipSections: ScholarshipSection;
    sponsors: SponsorObject;
  };
};

export const pageQuery = graphql`
  query($language: String, $type: String!) {
    navigationItem: allDirectusScholarshipType(filter: { name: { eq: $type } }) {
      nodes {
        name
        translation(language: $language) {
          value
        }
        slug
      }
    }

    scholarship: allDirectusHsfScholarshipMain(filter: { page: { name: { eq: $type } } }) {
      nodes {
        translation(language: $language) {
          scholarship_status_label
          apply_cta
          signin_cta
          about_section_title
          about_section_body
          sponsor_section_title
        }
        sponsor_section_visibility
        about_section_image {
          data {
            full_url
          }
        }
      }
    }

    scholarshipStages: allDirectusHsfScholarshipStage {
      nodes {
        translation(language: $language) {
          application_stage_status
          application_stage_date_segment
        }
        application_stage_year
      }
    }

    scholarshipSections: allDirectusHsfScholarshipSection {
      nodes {
        translation(language: $language) {
          section_title
          section_body
        }
      }
    }

    sponsors: allDirectusScholarshipSponsor(filter: { page: { name: { eq: $type } } }) {
      nodes {
        translation(language: $language) {
          scholarship_sponsor_type
        }
        scholarship_sponsor_logo {
          data {
            full_url
          }
        }
      }
    }
  }
`;

const ScholarshipMain = ({
  data: { navigationItem, scholarship, scholarshipStages, scholarshipSections, sponsors },
}: ScholarshipMainProps) => {
  const { translateSlug } = useI18NContext();
  const loggedIn = useSelector(isAuthenticated);
  return (
    <PublicLayout seoTitle="Scholarship Main">
      {scholarship && scholarship.nodes.length ? (
        <ScholarshipMainContainer>
          <PageHeader
            title=""
            breadcrumb={[
              <Link key="1" to={translateSlug('/')}>
                Home
              </Link>,
              navigationItem.nodes[0].translation?.value,
            ]}
            description={
              <>
                <Row>
                  <ScholarshipHeader>{navigationItem.nodes[0].translation?.value}</ScholarshipHeader>
                </Row>
                <HeaderWrapper>
                  <StatusWrapper>
                    {scholarship.nodes[0].translation?.scholarship_status_label === 'Open' ? (
                      <StatusOpened>
                        Status: <StatusValue>Open</StatusValue>
                      </StatusOpened>
                    ) : (
                      <StatusClosed>
                        Status: <StatusValue>{scholarship.nodes[0].translation?.scholarship_status_label}</StatusValue>
                      </StatusClosed>
                    )}
                  </StatusWrapper>
                  {loggedIn ? (
                    <BtnGroupWrapper />
                  ) : (
                    <BtnGroupWrapper>
                      <SigninBtn>
                        {' '}
                        <Button type="primary" onClick={()=> navigate('/login')} style={{ minWidth: '165px' }}>
                          {scholarship.nodes[0].translation?.apply_cta}
                        </Button>
                      </SigninBtn>
                      <Col style={{ paddingLeft: '5px' }}>
                        {' '}
                        <Button type="primary-blue" onClick={()=> navigate('/sign-up')} style={{ minWidth: '165px' }}>
                          {scholarship.nodes[0].translation?.signin_cta}
                        </Button>
                      </Col>
                    </BtnGroupWrapper>
                  )}
                </HeaderWrapper>
                {scholarshipStages.nodes ? (
                  <CalendarWrapper>
                    {scholarshipStages.nodes.map((stage: any, index: number) => {
                      return (
                        <CalendarItem key={index}>
                          <ItemBox>
                            <StatusTile>{stage.translation?.application_stage_status}</StatusTile>
                            {stage.translation?.application_stage_date_segment && (
                              <DayTile>{stage.translation?.application_stage_date_segment}</DayTile>
                            )}
                            {stage.application_stage_year && <YearTile>{stage.application_stage_year}</YearTile>}
                          </ItemBox>
                        </CalendarItem>
                      );
                    })}
                  </CalendarWrapper>
                ) : (
                  ''
                )}
              </>
            }
            fullWidth
            isResponsive
          />
          <AboutInfoContainer>
            <AboutBody>
              <SectionTitle>
                {scholarship.nodes[0].translation?.about_section_title
                  ? scholarship.nodes[0].translation?.about_section_title
                  : ''}
              </SectionTitle>
              <SectionBody
                dangerouslySetInnerHTML={{
                  __html: scholarship.nodes[0].translation?.about_section_body
                    ? scholarship.nodes[0].translation?.about_section_body
                    : '',
                }}
              />
            </AboutBody>
            <AboutImage>
              {scholarship.nodes[0].about_section_image.data.full_url ? (
                <img alt="scholarship" src={scholarship.nodes[0].about_section_image.data.full_url} />
              ) : (
                ''
              )}
            </AboutImage>
          </AboutInfoContainer>
          {scholarshipSections.nodes &&
            scholarshipSections.nodes.map((section: any, index: number) => {
              return (
                <SectionWrapper key={index}>
                  <SectionTitle>
                    {section.translation?.section_title ? section.translation?.section_title : ''}
                  </SectionTitle>
                  <SectionBody
                    dangerouslySetInnerHTML={{
                      __html: section.translation?.section_body ? section.translation?.section_body : '',
                    }}
                  />
                </SectionWrapper>
              );
            })}

          {scholarship.nodes[0].sponsor_section_visibility && (
            <DividerWrapper>
              <SponsorsContainer>
                <SponsorsSectionTitle>{scholarship.nodes[0].translation?.sponsor_section_title}</SponsorsSectionTitle>
                {sponsors.nodes.length ? (
                  <SponsorsWrapper>
                    {sponsors.nodes.map((sponsor: any, index: number) => {
                      return (
                        <SponsorItem key={index}>
                          <SponsorType>
                            {sponsor.translation?.scholarship_sponsor_type
                              ? sponsor.translation?.scholarship_sponsor_type
                              : ''}
                          </SponsorType>
                          {sponsor.scholarship_sponsor_logo.data.full_url ? (
                            <SponsorLogosWrapper>
                              <SponsorLogo>
                                {' '}
                                <img alt="brand" src={sponsor.scholarship_sponsor_logo.data.full_url} />
                              </SponsorLogo>
                            </SponsorLogosWrapper>
                          ) : (
                            <SponsorLogosWrapper />
                          )}
                        </SponsorItem>
                      );
                    })}
                  </SponsorsWrapper>
                ) : (
                  <SponsorsWrapper />
                )}
              </SponsorsContainer>
            </DividerWrapper>
          )}
        </ScholarshipMainContainer>
      ) : (
        <ScholarshipMainContainer />
      )}
    </PublicLayout>
  );
};

export default ScholarshipMain;
