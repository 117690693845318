import React, { memo, useCallback, useMemo } from 'react';
import { Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Select } from 'antd5';
import { debounce } from 'lodash';

import { Input } from '../../../components/forms';
import theme from '../../../theme';

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px auto 10px auto;
  max-width: ${theme.screenXl};
  height: auto;
  min-height: 10vh;
  @media (max-width: ${theme.screenLgMax}) {
    padding: 0 24px;
    margin: 0;
    max-width: none;
  }
`;

const FilersContentEnd = styled.div`
  display: flex;
  flex-basis: 50%;

  align-items: center;
  justify-content: end;
`;

const FilersContentStart = styled.div`
  display: flex;
  flex-basis: 50%;

  align-items: center;
  justify-content: start;
`;

const ItemDiv = styled.div`
  width: 300px;
  @media (max-width: ${theme.screenSmMax}) {
    width: 150px;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;

  && {
    box-shadow: none !important;
    border-radius: 4px !important;
    line-height: 42px !important;
    border: 1px solid ${theme.colorsDisabledGreyButtons};
    outline: none !important;
    box-sizing: border-box;
    min-height: 50px;
  }

  &&:hover,
  &&:focus-within {
    border-width: 2px !important;
    border-color: ${theme.colorsPrimaryBlue} !important;
  }
`;

const StyledSelect = styled(Select)`
  min-height: 55px;
  min-width: 100%;
  && .ant-input {
    height: 100% !important;
    justify-content: center;
    align-items: center;
  }

  && .ant-select-selection-placeholder {
    text-align: start;
  }

  .ant-select-selection-overflow-item {
  }

  && {
    box-shadow: none !important;
    border-radius: 4px !important;
    border: 1px solid ${theme.colorsDisabledGreyButtons};
    outline: none !important;
    box-sizing: border-box;
  }

  &&:hover,
  &&:focus-within {
    border-width: 2px !important;
    border-color: ${theme.colorsPrimaryBlue} !important;
  }

  && .ant-select-outlined {
    border: none;
    box-shadow: none !important;
  }

  && .ant-select-multiple {
    border: none;
    box-shadow: none !important;
  }

  && .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }

  && .ant-select-show-arrow {
    border: none;
    box-shadow: none !important;
    shadow: none !important;
  }

  && .ant-select-show-search {
    border: none;
    box-shadow: none !important;
    shadow: none !important;
  }

  && input {
    height: 100% !important;
  }

  && .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  && .anticon-close {
    align-items: center;
    color: ${theme.colorsBlack};
    display: flex;

    svg {
      font-size: 12px;
    }
  }
`;

type Props = {
  filters: any;
  majors: any;
  handleOnChange: (value: any) => void;
  handleOnBlur?: (value: any) => void;
  handleSearch: (data: any) => void;
};

const CareerFilter = memo(function CareerFilter({ filters, handleOnChange, handleSearch, majors }: Props) {
  const majorOptions = useMemo(() => {
    const results = majors && [].concat(majors.map((item: any) => ({ label: item.displayText, value: item.id })));
    return results;
  }, [majors]);

  const debouncedSearch = debounce((value) => {
    handleSearch(value);
  }, 300);

  const onSearchChange = useCallback(
    (e) => {
      handleOnChange && handleOnChange(e);
      const newFilterValue = { ...filters };
      newFilterValue['searchText'] = e.value;
      debouncedSearch(newFilterValue);
    },
    [filters, handleOnChange, debouncedSearch]
  );

  const onChange = useCallback(
    (e) => {
      handleOnChange && handleOnChange(e);
      const newFilterValue = { ...filters };
      newFilterValue['major'] = e;
      handleSearch(newFilterValue);
    },
    [filters, handleOnChange, handleSearch]
  );

  return (
    <>
      <FiltersContainer>
        <Content>
          <FilersContentStart>
            <ItemDiv>
              <Form.Item>
                <StyledInput
                  prefix={<SearchOutlined />}
                  name={'searchText'}
                  value={filters.searchText}
                  onChange={onSearchChange}
                />
              </Form.Item>
            </ItemDiv>
          </FilersContentStart>
          <FilersContentEnd>
            <ItemDiv>
              <Form.Item>
                <StyledSelect
                  showSearch
                  mode="multiple"
                  placeholder="Select to filter by major"
                  options={majorOptions}
                  value={filters.major}
                  onChange={onChange}
                  filterOption={(input, option) =>
                    !!option &&
                    option.props !== null &&
                    option.props.children !== null &&
                    typeof option.props.children === 'string' &&
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </ItemDiv>
          </FilersContentEnd>
        </Content>
      </FiltersContainer>
    </>
  );
});

export default CareerFilter;
